.main-container {
    padding-top: 75px;
    min-height: calc(100vh - 220px);
	display: flex;
    align-items: center;
}

.panel {
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 700px;
    width: 100% !important;
    position: relative;
    border-width: 0;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    background-color: #fafafa!important;
}

.panel-head {
    border-bottom: 1px solid #e4eaec;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    position: relative;
    padding: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.head-text {
    margin-right: 30px !important;
    margin-left: 30px !important;
    font-size: 24px;
}

.panel-body {
    padding-top: 30px;
    position: relative;
    padding: 50px;
}

.textfield-login {
    background-color: #E8F0FE !important;
}

.input-container {
    margin-top: 11px;
    margin-bottom: 11px;
}

.checkbox-container{
    display: flex;
    margin-bottom: 10px;
}

.registerCheckbox{
    margin-right: 10px;
    width: 20px;
}

.check-topics{
    margin: 20px 0px 20px 30px;
}