@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&family=Roboto:wght@300&display=swap");


.main-container {
  margin-right: auto !important;
  margin-left: auto !important;
  padding-bottom: 30px !important;
  padding-top: 25px !important;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  height: 75vh;
}

.icon-flex {
  display: flex;
}

.link {
  text-decoration: none;
}

h3,
h2 {
  font-family: "Roboto", sans-serif;
  text-shadow: rgb(0 0 0 / 15%) 0 0 1px;
  margin-top: 22px;
  margin-bottom: 11px;
  font-weight: 400;
  line-height: 1.2;
  color: #37474f;
}

.button {
  font-family: "Roboto", sans-serif;
  color: white;
  margin-bottom: 10px;
  background-color: #dc042b;
  border-color: #dc042b;
  font-size: 14px;
  font-weight: 300;
  padding: 5px 25px 5px;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  text-decoration: none !important;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  padding-top: 5px;
}

.button-text {
  font-size: 16px;
  padding-top: 10px;
  text-decoration: none;
}

h4,
.h4-title {
  color: #37474f;
  margin-top: 0;
  margin-bottom: 11px;
  line-height: 1.2;
  font-size: 18px;
  text-shadow: rgb(0 0 0 / 15%) 0 0 1px;
}

.textfield {
  max-width: 100%;
  width: 268px;
  display: block;
  padding: 6px 15px;
  font-size: 14px;
  line-height: 1.57142857;
  border: 1px solid #e4eaec;
  border-radius: 3px;
  margin: 0;
  background-image: none !important;
  color: fieldtext !important;
}

.textfield:focus {
  border-color: #62a8ea;
  outline: 0;
}

a {
  color: #dc042b;
  cursor: pointer;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

.col-sm-6,
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

img {
  width: auto;
}

label {
  display: inline-block;
  max-width: 100%;
  font-weight: normal !important;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}

.pagination-wrapper {
  justify-content: center;
  display: flex;
}

.label:hover {
  background-color: #555555;
}

/* input[type=checkbox] {
    width: 20px;
    z-index: 1;
    height: 20px;
    background-color: #fff;
    border: 1px solid #e4eaec;
    border-radius: 3px;
    -webkit-transition: all .3s ease-in-out 0s;
    -o-transition: all .3s ease-in-out 0s;
    transition: all .3s ease-in-out 0s;
    position: absolute;
    box-sizing: inherit;
    line-height: normal;
    opacity: 0;
} */

input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-transition-ring-color;
  outline-offset: -2px;
}

.select-control {
  display: block;
  width: 100%;
  height: 38px;
  font-size: 14px;
  color: #76838f;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e4eaec;
  border-radius: 3px;
  transition: box-shadow 0.25s linear, border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
  margin-bottom: 15px;
  padding: 5px;
}

table {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-spacing: 0;
  border-collapse: separate;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  background-color: #dc042b !important;
}

tbody tr:nth-child(odd), tbody tr:nth-child(odd) td{
  background-color: white !important;
}

tbody tr:nth-child(even), tbody tr:nth-child(even) td{
  background-color: rgb(247, 244, 244) !important;
}

th {
  text-align: left;
  color: white !important;
  vertical-align: middle !important;
  position: static;
  display: table-cell;
  float: none;
  box-sizing: content-box;
  line-height: 1.6;
  font-weight: 400;
  border-bottom: 1px solid #e4eaec;
  padding: 10px 18px;
  cursor: pointer;
  background-position: center right 5%;
  margin-top: 0;
  border-top: 0;
}

.sorting {
  background: url(../src/images//sort_both.png) no-repeat center right !important;
}

.desc {
  background: url(../src/images/sort_desc.png) no-repeat center right;
}

.asc {
  background: url(../src/images/sort_asc.png) no-repeat center right;
}

.col-sm-1 {
  width: 8%;
}

.col-sm-2 {
  width: 17%;
}

.col-sm-3 {
  width: 25%;
}

.col-sm-5 {
  width: 42%;
}

.map-style {
  margin-left: auto;
  margin-right: auto;
  max-height: 200px;
}

.map-info-window {
  width: 100px;
  height: 100px;
  background-color: #dc042b;
  z-index: 1000;
}

.subheader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  background-color: #dc042b !important;
  position: relative;
  padding: 10px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  z-index: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.h2-title {
  color: white;
  float: left;
  font-size: 24px;
  margin: 1.2rem 1rem !important;
}

.label {
  color: #fff;
  background-color: #c3c3c3;
  border-radius: 0.2em;
  float: left;
  font-size: 100%;
  font-weight: 500;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-top: 15px !important;
  padding: 0.5rem 1rem;
}

/* Pagination CSS - Start */
.pagination {
  float: right;
  display: inline-block !important;
  padding-top: 0;
  margin: 22px 0;
  border-radius: 3px;
  list-style: none;
}

.page-item {
  display: inline-block;
  min-width: 1.5em;
  box-sizing: border-box;
  margin-left: 2px;
  color: #333 !important;
  text-align: center;
  text-decoration: none !important;
  border: 1px solid transparent;
}

.page-item:not(.disabled, .active) .page-link {
    color: #e40d0d !important;
}

.page-link {
  background-color: transparent;
  padding: 0.40rem 1rem !important;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 164, 179, 0.5) !important;
}

.page-item.active .page-link {
    background-color: #ff3547 !important;
    border: 1px solid #ff3547 !important;
    border-radius: 0.125rem !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
/* Pagination CSS - End */

.col-xs-4 {
  width: 33.33333333% !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

/* .col-xs-4::after {
    position: absolute;
    top: 18px;
    z-index: -1;
    width: 50%;
    height: 4px;
    content: "";
    background-color: #f3f7f9;
    right: 0;
} */

.form-container {
  border-width: 0;
  width: 90%;
  max-width: 1200px;
  min-width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  text-align: left;
}

.input-group-file {
  display: flex;
  flex-direction: row;
}

.texteditor {
  margin-bottom: 15px !important;
  border: 1px solid #76838f !important;
  height: 150px;
}

.results-container{
  overflow-x: scroll;
}

/* .custom-check label::before {
    content: "";
    position: absolute;
    margin-left: -28px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #e4eaec;
    border-radius: 3px;
} */

/* .custom-check input[type=checkbox]:checked+label::before {
    background-color: #DC042B !important;
    border-color: #DC042B !important;
} */

/* .custom-check label::after {
    content: "";
    position: absolute;
    left: 54px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
} */

.admin-header {
  margin-right: auto !important;
  margin-left: auto !important;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 25px !important;
  display: flex;
  justify-content: space-between;
}

.icons {
  margin-right: 1rem;
  width: 20px;
  padding-right: 5px;
  vertical-align: middle;
}

.web-information {
  text-align: center !important;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}

.links {
  text-decoration: none;
  color: #dc042b;
}

.links:hover {
  text-decoration: underline;
  color: #dc042b;
  cursor: pointer;
}

td {
  padding: 8px 10px;
  line-height: 1.57;
  vertical-align: center;
  border-top: 1px solid #e4eaec;
  background-color: #fff !important;
}

.name-td {
  font-weight: bold;
  color: black;
}

.table-button {
  width: 52px;
  height: 36px;
  margin-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-icons {
  width: 14px;
  height: 14px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(312deg)
    brightness(103%) contrast(105%);
}

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-button {
  display: none;
}



.temporality-button {
  font-size: 14px !important;
  font-weight: 300 !important;
  /* width: 108px !important; */
  height: 34px !important;
  margin-right: 5%;
  justify-content: center;
  display: block;
}

.ACToolbar {
  display: none;
}

.img-preview {
  max-width: 300px;
  margin-left: 10px;
}

.invalid-feedback {
  padding: 5px;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  padding-left: 35px;
  max-width: 400px;
  background-image: url("./images/circle-exclamation-solid.svg");
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position-y: center;
  background-position-x: 5px;
  filter: invert(9%) sepia(67%) saturate(6402%) hue-rotate(344deg)
    brightness(126%) contrast(100%);
}

.invalid-feedback-custom {
  /* width: 90%; */
  padding: 5px;
  border-radius: 5px;
  color: black;
  border: 1px solid #000000;
  padding-left: 35px;
  max-width: 400px;
  background-image: url("./images/circle-exclamation-solid.svg");
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position-y: center;
  background-position-x: 5px;

  /* border-width: 0; */
  width: 80%;
  max-width: 1200px;
  min-width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  text-align: left;
  filter: invert(9%) sepia(67%) saturate(6402%) hue-rotate(344deg)
    brightness(126%) contrast(100%);
}

.wrapperClassNamePerformance {
  color: #c1c1c1;
}