.home-container {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  min-height: 77.5vh;
  justify-content: space-between;
  flex-wrap: wrap;

  .home-tabs {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 7%;

    .home-element {
      cursor: pointer;
    }
  }
}

.home-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
}

.button-text {
  font-size: 16px;
  text-decoration: none;
}

.botones-inicio {
  height: 320px;
  flex-shrink: 1;
  width: 320px;
  max-width: 100%;
  background-color: white;
  display: inline-block;
  text-align: center;
  border: 1px solid #b3b3b3;
  background-repeat: no-repeat;
  background-position: center 35%;
  margin-right: 2.5px;
  margin-bottom: 15px;
  text-decoration: none;
}

.guide {
  flex-basis: 100%;
  margin-top: 1rem;
  display: flex;
  background-color: #efefef;
  justify-content: center;
  width: 60%;
  margin-bottom: 3rem;
  padding: 1rem 0;
  flex-wrap: wrap;

  .guide-text {
    padding-right: 15px;
    margin-top: auto;
    color: #37474f;
    margin-top: auto;
    margin-bottom: 15px;
    font-size: 18px;
    text-align: center;
  }
}

.button-icon {
  padding: 5px 25px 10px;
}

.home-tabs-active {
  color: white;
}

.botones-inicio:hover {
  color: white;
  background-color: red;
  transition: all 0.2s;
  text-decoration: none !important;
}

.botones-inicio>div:first-child {
  margin-top: 65%;
  font-weight: 300;
  font-size: 2em;
  color: #dc042b;
  transition: all 0.2s;
  //margin: 1rem;
  padding: 0 1.2rem;
}

.botones-inicio>div:last-child {
  margin-top: 5px;
  color: #4c4c4e;
  transition: all 0.2s;
}

.searcher {
  background-image: url(https://activosdesalud.com/images/red/buscadorn_obsa.png);
}

.activities {
  background-image: url(https://activosdesalud.com/images/red/actividades_obsa.png);
}

.resource {
  background-image: url(https://activosdesalud.com/images/red/recursos_obsa.png);
  background-size: 128px;
}

a.botones-inicio:hover>div:first-child,
a.botones-inicio:hover>div:nth-child(2),
a.botones-inicio:hover>div:last-child {
  color: #ffffff;
  transition: all 0.2s;
}

a.activities:hover {
  background-image: url(https://activosdesalud.com/images/red/actividades_w.png);
  transition: all 0.2s;
}

a.resource:hover {
  background-image: url(https://activosdesalud.com/images/red/recursos_w.png);
  transition: all 0.2s;
}

a.searcher:hover {
  background-image: url(https://activosdesalud.com/images/red/buscadorn_w.png);
  transition: all 0.2s;
}

.download-icon {
  width: 25px;
  filter: invert(95%) sepia(95%) saturate(0%) hue-rotate(155deg) brightness(106%) contrast(106%);
}