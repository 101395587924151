.temporality-container {
  width: 100%;
  margin-top: 30px;
}

.control-label {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.temporality-fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.temperality-field {
  width: 190px;
  margin-left: 30px;
}

.control-label {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.ubication-inputs {
  width: 100% !important;
  padding: 0 !important;
}

.temporality-button-container {
  display: flex;
  width: 100%;
  justify-content: left;
  margin-bottom: 30px;
}



.actions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.actions-button {
  width: 46px;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

.actions-img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(185deg)
    brightness(111%) contrast(101%);
  height: 14px;
  width: 14px;
}
