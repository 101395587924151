.main-container {
  margin-right: auto !important;
  margin-left: auto !important;
  padding-top: 75px !important;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;

  .subheader-activities-admin {
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
    justify-content: flex-start;
    align-items: center;

    .select-activities {
      max-width: 200px;
      float: left;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  }

  .subheader-activities {
    margin-bottom: 15px !important;
  }

  .filters-container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .filters {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;

      .filter {
        color: #76838f;
        float: left;
        font-size: 90%;
        padding: 10px !important;
        margin-right: 10px !important;
        margin-bottom: 10px !important;
        margin-top: 0px;
        background-color: #e0e0e0 !important;
        height: 44px;
        font-weight: 300;
      }

      .filter-active {
        color: #fff;
        background-color: #dc042b !important;
      }
    }
  }

  .searcher-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px !important;
    justify-items: center;
  }

  .searcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
  }

  .search-control {
    width: 170px;
  }

  .btn-search {
    font-size: 100%;
    padding: 10px 10px 10px 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }

  .searcher-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    color: #37474f;
  }

  .btn-delete {
    width: 180px !important;
    min-width: max-content;
  }

  .btn-container {
    overflow: auto;
    clear: both;
    margin-bottom: 50px !important;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    justify-content: center;

    .btn-csv-download {
      width: 165px;
      height: 45px;
      min-width: max-content;

      .csv-icon {
        width: 20px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(185deg)
          brightness(111%) contrast(101%);
      }

      .btn-text-csv {
        padding-top: 5px;
        padding-left: 5px;
      }
    }
  }
}

.icons {
  padding-left: 5px !important;
}

.footer-container {
  margin-top: 10px;
}

.table-headers {
  padding: 8px 10px !important;
}

.actions-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end;
}

.actions-button {
  width: 46px;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

.actions-img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(185deg)
    brightness(111%) contrast(101%);
    height: 16px;
    width: 16px;
}

.actions-input{
  opacity: 1 !important;
  position:unset !important;
}

.activities-name{
  font-weight: bold;
  line-height: 1.57142857;
  color: black,
}