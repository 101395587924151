.main-container{
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 55px 15px 30px 15px !important;
    max-width: 1200px;
    width: 100% !important;

    .sa-panel-heading{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        background-color: #DC042B !important;
        padding-top: 3px !important;
        padding-bottom: 3px !important;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
}

.activity-img {
    max-width: 50vh;
    max-height: 28vh;
    margin-bottom: 50px;
    cursor: pointer;
}

.situation-active-container{
    margin-right: 15px;
    float: left;
    font-size: 100%;
    font-weight: 500;
    background-color: #46be8a;
    padding: 0.25em 0.6em 0.25em;
    border-radius: 0.3em;
    color: #fff;
}

.situation-historical-container{
    margin-right: 15px;
    float: left;
    font-size: 100%;
    font-weight: 500;
    background-color: #f6be80;
    padding: 0.25em 0.6em 0.25em;
    border-radius: 0.3em;
    color: #fff;
}

.sa-body-panel{
    width: 100%;
    background-color: rgb(250, 250, 250);
}

.sa-top-panel{
    /* display: flex;
    flex-wrap: wrap;
    padding: 40px 40px 0px 40px; */
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 40px 0px 0px 40px;
}

.sa-title{
    display: flex;
    width: 50%;
}

.sa-topics{
   // width: 50%;
}

.sa-summary-block{
    display: block;
    width: 100%;
    
}

.sa-summary-none{
    display: none;
    width: 100%;
}

.sa-summary-big-block{
    display: block;
    font-size: 18px;
}

.sa-summary-big-none{
    display: none;
    font-size: 18px;
}

.sa-summary-container{
    margin: 30px 30px 0px 30px;
    grid-column-start: 1;
    grid-column-end: 3;
}

.sa-button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #DC042B;
    border-color: #DC042B;
    touch-action: manipulation;
    cursor: pointer;
    text-decoration: none !important;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid transparent;
    box-sizing: border-box;
    height: 36px;
}

.sa-button-text{
    font-weight: 300;
    font-size: 14px;
    flex-shrink: 0;
}

.sa-mid-panel{
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
}

.sa-left-panel{
    max-width: 500px;
}

.separator{
    display: flex;
    flex-wrap: wrap;
    min-height: 60px;
    width: 100%;
}

.separator-title{
    width: 100%;
    padding-left: 40px;
    padding-top: 20px;
}

.separator-text{
    width: 100%;
    padding-left: 40px;
}

.sa-right-panel{
    width: 50%;
}

.back-button-container{
    max-width: 1200px;
    width: 100%;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-bottom: 10%;
}

.back-button{
    float: right;
    padding: 10px;
    margin-bottom: 30px;
}

.btn-icon {
    width: 14px;
    height: 14px;
    padding-right: 5px;
    filter: invert(100%) sepia(0%) saturate(6158%) hue-rotate(138deg) brightness(123%) contrast(112%);
}

.button-download {
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 6px 15px;
    margin-left: 40px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.title-image{
    width: 30px;
    height: 24px;
}

.map{
    width: 510px;
    height: 300px;
}

.btn-text {
    font-weight: 300;
    font-size: 14px;
}

.sa-details-button{
    width: 110px;
    margin-top: 10px;
}

.sa-topics-image{
    width: 25px;
    margin-right: 25px;
    vertical-align: middle;
}

.sa-topics-container{
    padding-left: 10px;
}

