.footer {
  padding: 2rem 1rem;
  background-color: #efefef;
  width: 100%;
  color: #747474;
  text-align: center;
  margin-top: 1rem;
  position: relative;
  bottom: 0;
}

.logos-container {
  margin-bottom: 2rem;
}

.footer-img {
  height: 90px;
  margin: 0rem 2.5rem;
}

.legal-warning-container {
  margin-bottom: 1.5rem;
  a {
    color: #999999;
  }
  span {
    color: #999999;
  }
}

.footer-icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.footer-icon {
  height: 35px;
  margin: 0rem 0.30rem;
}