.main-container {
    
    padding: 15px 15px;
    padding-top: 25px;
    padding-bottom: 30px;
    margin-right: auto;
    margin-left: auto;

    .elements-container {

        padding: 30px 30px;
        display: flex;
        flex-direction: row;
        min-height: 75vh;
        overflow-y: hidden;

        .columns {

            padding: 30px 30px;
            position: relative;
        }

        .column-right {
            padding: 0;
            padding-top: 0px;
        }

        .column-left {
            padding-right: 45px;
            -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
            height: fit-content;
        }

    }
}

.elements {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    color: #76838f;
   // width: 300px;
    margin-bottom: 20px;
    border-radius: 3px;
}

.columns > :last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.columns > :first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.elements:hover {
    
    text-decoration: none;
    background-color: #f3f7f9;
}

.elements-active {
    color: #fff;
    background-color: #DC042B !important;
    z-index: 2;
    border-color: transparent;
}

.questions {
    padding: 30px 30px;
    display: block;
    .question {
        padding-right: 40px;
        padding-left: 0px;
        padding-bottom: 10px;
        font-size: 120% !important;
        color: #37474f;
        cursor: pointer;
        -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
        transition: margin .5s;
    }
}

.icons {
    width: 16px;
    position: absolute;
    right: 10%;
    filter: invert(54%) sepia(25%) saturate(212%) hue-rotate(167deg) brightness(88%) contrast(87%);
    transform: rotate(-180deg);
}

.question:hover {
    color: #76838f;
}

p, ul {
    color: #76838f !important;
    font-size: 14px;
}

p:hover{
    cursor:auto !important;
}

ul:hover{
    cursor: auto !important;
}


