.main-container {
  padding-bottom: 30px !important;
  padding-top: 25px !important;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1800px !important;
}

.header {
  height: 60px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .header-text {
      display: flex;
      align-items: center;
  }

}

.body-style {
  width: 90%;
  max-width: 1200px;
  margin: 15px auto 40px auto;
}

.icons-container {
  padding-top: 20px !important;
  margin-bottom: 22px;
  display: flex;

  .icon-circle {
      position: relative;
      padding: 0;
      margin: 0;
      z-index: 1;
      display: flex;
      width: 36px;
      height: 36px;
      line-height: 32px;
      justify-content: center;
      background: #ccd5db;
      border: 2px solid #ccd5db;
      border-radius: 50%;
      font-size: 18px;
  }

}

.circle-active {
  transform: scale(1.2) !important;
  background: #fff !important;
  border-color: #DC042B !important;
  z-index: 1 !important;
}

.circle-completed {

  
  background: #DC042B !important;
  border-color: #DC042B !important;
  z-index: 1 !important;

}

.icon-active {
  color: #DC042B;
  filter: invert(24%) sepia(92%) saturate(4972%) hue-rotate(339deg) brightness(81%) contrast(116%);

}

.icon-completed {
  color: #DC042B;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(268deg) brightness(107%) contrast(109%);
}

.icon-desactive {
  filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(200deg) brightness(101%) contrast(101%);
  width: 18px !important;
}

.circle-text {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 16px;
  color: #526069;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
}

.form-control {
  width: 570px !important;
  margin-bottom: 15px !important;
  padding: 0 5px 0 5px !important;
}

.requieredfield {
  margin-bottom: 15px!important;
  width: 100%;
}

.control-label {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
  color: #76838f !important;
  font-size: 14px !important;
}

.data-container {
  width: 50%;
  margin-bottom: 15px !important;
}

.first-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.select-inputs {
  height: 36px !important;
  width: 570px !important;
  margin-bottom: 15px !important;
}

.temporal-field {
  right: 0;
}

.ubication-fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ubication-fields{
  width: 145px;
  margin-right: 5px;
}

.ubication-selects {
  width: 100%;
  margin-right: 11px !important;
}

.ubication-inputs {
  width: 100% !important;
  padding: 0 !important;
}

.ubication-coordinates{
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5%;
}

.ubication-coordinates p{
  flex-basis: 100%;
  margin-bottom: 0 !important;
}

.map-container {
  height: 300px;
  width: 70%;
  display: flex;
}

.map-container:first{
  width: 100px;
  border: 5px black solid;
}

.buttons-container {
  display: flex;
  flex-direction: row;

  .place-button {
      font-weight: 300 !important;
      font-size: 14px !important;
      height: 34px;
      width: 125px;
      min-width: max-content;
      margin-right: 5px;
  }
}

.info-container {
  display: flex;
  justify-content: center;
}

.info{
  position: relative;
  font-size: 100%;
  margin-bottom: 15px !important;
  background-color: rgba(242,166,84,.1);
  padding: 15px 20px;
  border-left: 4px solid #f2a654;
  border-radius: 3px;
  width: 90%;
  max-width: 1200px;

}

.buttons-container-index {
  display: flex;
  width: 1200px;
  justify-content: right;

  .button-index {
      font-size: 14px !important;
      font-weight: 300 !important;
      width: 108px !important;
      height: 34px !important;
      margin-left: 5px;
      justify-content: center;
  }

  .button-return {
      background-color: #c8c8c8;
      width: 108px !important;
      height: 34px !important;
      margin-right: 5px;
      justify-content: center;
      min-width: max-content;
      color: #526069;
      font-weight: 300;
  }

  .button-restart{
      background-color: #EFEFEF;
      color: #76838F
  }

}

.footer-container {
  display: flex;
  justify-content: center;
}

.upload-button {
  display: flex;
  flex-direction: row;
  width: 15px !important;
  
  .upload-icon {
      width: 14px;
      height: 22px;
  }

  .file-input {
      position: absolute;
      cursor: pointer;
      margin-left: -25px;
      opacity: 0;
      border-radius: 0px !important;
      margin-left: -10px !important
  }

}

.rigth-input {
  justify-content: flex-end !important;
}

.check-activity {
  left: -30px;
}

.text-topics::after {
  left: -20px !important;
}

.icon-check {
  padding-top: 6px;
  height: 25px;
}

.check-topics {
  position: relative;
  display: block;
  margin: 20px 0px 20px 30px;

  color: #37474f;
  line-height: 1.2;
  font-size: 18px;
  text-shadow: rgb(0 0 0 / 15%) 0 0 1px;
}

.confirmation-element {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid transparent;
}

.temporality-container{
  width: 50%;
  margin-top: 30px;
}

.temporality-fields-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.temperality-field{
  width: 190px;
  margin-left: 30px;
}

.checkbox-container{
  display: flex;
  align-items: center;
  
}

.formCheckbox{
  opacity: 1 !important;
}

.temporality-button-container{
  align-items: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 30px;
}

.temporality-button{
  font-size: 14px !important;
  font-weight: 300 !important;
  width: 20% !important;
  min-width: 180px;
  height: 34px !important;
  margin-right: 5px;
  margin-left: auto;
  justify-content: center;
  flex-wrap: wrap;
}

.actions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.actions-button {
  width: 46px;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}
.actions-img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(185deg)
    brightness(111%) contrast(101%);
  height: 14px;
  width: 14px;
}