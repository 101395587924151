.main-container {
  margin-right: auto !important;
  margin-left: auto !important;
  padding-top: 75px !important;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;

  .subheader-activities-admin {
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
    justify-content: flex-start;
    align-items: center;
  }

  .adminContainer {
    margin-right: auto !important;
    margin-left: auto !important;
    width: 90%;
    max-width: 1200px;
    margin-bottom: 25px !important;
    display: flex;
    justify-content: space-between;
  }

  .admin-content{
    padding: 0px 30px 30px 30px;
  }

  .icons {
    padding-left: 5px !important;
  }

}
