.main-container {
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  padding-top: 55px !important;
  padding-bottom: 30px !important;
  flex-wrap: wrap;

  .button-download {
    margin-bottom: 0;
    width: 167px;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin-left: auto;
    //padding: 6px 15px;
    //margin-bottom: 15px;
    //margin-top: 40px;
  }

  .types-filter {
    border: 1px #ccc solid;
    padding: 20px;
  }

  .topics-filter {
    width: 50%;
  }

  .others-filter {
    width: 50%;
    flex-direction: column;
  }

  .custom-check {
    padding-left: 20px;
  }

  .check-topics {
    //position: relative;
    //display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .topics-input {
    accent-color: #dc042b;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  .topics-container {
    margin-bottom: 20px;
  }

  .text-topics {
    margin-bottom: 0 !important;
    padding-left: 10px !important;
    cursor: pointer !important;
  }

  .text-results {
    margin-right: auto !important;
    margin-left: auto !important;
    font-weight: bold;
    position: relative;
    display: block;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    text-align: center !important;
    min-width: 180px;

  }


  .map-container {
    position: relative;
    margin-bottom: 30px;
    height: 500px;
    width: 100% !important;
  }
}

.plus-icon {
  width: 22px;
  padding-right: 10px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(230deg)
    brightness(200%) contrast(200%);
}

.filters > :last-child {
  right: 30px;
  position: absolute;
  display: flex;
  color: #ffffff;
}

.icons {
  width: 25px;
  padding-right: 5px;
  vertical-align: middle;
}

.search-button {
  height: 36px;
  padding: 10px 10px !important;
  width: 82px;
  justify-content: center;
  align-items: center;
  float: right;
  margin: 0px !important;
}

.buscar{
  background-color: white !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;
}

.buscar-content{
  flex: 1 1 0px;
}

.keyword-textfield {
  display: block;
  color: #76838f;
  background-color: #fff;
  border: 1px solid #e4eaec;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 5px;
  width: 100%;
  height: 38px;
}

.btn-icon {
  width: 20px;
  height: 14px;
  padding-right: 5px;
  filter: invert(100%) sepia(0%) saturate(6158%) hue-rotate(138deg)
    brightness(123%) contrast(112%);
}

.btn-text {
  font-weight: 300;
  font-size: 14px;
}

.search-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.table-searcher-label {
  font-weight: bold !important;
  //margin-bottom: 25px;
  font-size: 24px !important;
  display: flex;
  flex-direction: row;
  //height: 62px;
}

.table-searcher-input {
  padding: 6px 13px;
  line-height: 1.5;
  border-radius: 2px;
  display: inline-block;
  width: auto !important;
  vertical-align: middle;
  margin-right: 10%;
  margin-left: 8px;
  margin-bottom: 0px !important;
}

.text-topics::after {
  left: 34px !important;
}

.filter-result {
  flex: 1 1 0px;
  margin-right: 40px;
  cursor: pointer;
  display: flex;
  color: #fff;
}

.header-right-items {
  display: flex;
  align-items: center;
}

.actions-img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(185deg)
    brightness(111%) contrast(101%);
  height: 14px;
  width: 14px;
}


@media (max-width: 600px){
  .buscar{
    background-color: white !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .button-download {
    margin-bottom: 0;
    width: 167px;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin-left: 0;
  }

  .results{
    overflow-x: scroll;
  }

}