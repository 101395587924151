.main-container {
    padding-top: 75px;
    height: calc(100vh - 220px);
	display: flex;
    align-items: center;

    .panel {
        margin-right: auto !important;
        margin-left: auto !important;
        max-width: 700px;
        //width: 100% !important;
        //position: relative;
        border-width: 0;
        border: 1px solid lightgray;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    }

    .panel-head {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        //position: relative;
        padding: 0;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center ;

        .head-text {
            margin-right: 30px !important;
            margin-left: 30px !important;
            font-size: 24px;
        }


    }

    .img{
        width: 200px;
    }

    .img-container{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 25px;
    }

    .text{
        color: #adadad;
        padding: 25px;
    }

}