.main-container {
    padding-top: 75px;
    min-height: calc(100vh - 220px);
    display: flex;
    align-items: center;

    .panel {
        margin-right: auto !important;
        margin-left: auto !important;
        max-width: 700px;
        width: 100% !important;
        position: relative;
        border-width: 0;
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
        background-color: #fafafa !important;

    }

    .panel-head {
        border-bottom: 1px solid #e4eaec;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        position: relative;
        padding: 0;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        .head-text {
            margin-right: 30px !important;
            margin-left: 30px !important;
            font-size: 24px;
        }

    }

    .panel-body {
        padding-top: 30px;
        position: relative;
        padding: 25px 50px 50px;

        .textfield-login {
            background-color: #E8F0FE !important;
        }

    }
}

.input-title {
    color: #37474f;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 11px;
    margin-top: 0;
    text-shadow: rgba(0, 0, 0, .15) 0 0 1px;
}

.login-button-text {
    font-size: 14px;
    padding-top: 2px !important;
    min-width: max-content;
}

.login-form {
    margin-top: 15px;
}

.input-container {
    margin-bottom: 15px;
    font-size: 0.8rem !important;
}