.main-container {
    padding-top: 65px;
}

.admin-header {
    margin-right: auto !important;
    margin-left: auto !important;
    width: 90%;
    margin-bottom: 25px !important;
    display: flex;
    justify-content: space-between;
}

.info-box {
    margin: 0.5rem 0rem;
}

.result {
    color: #c1c1c1;
}

.status-bar-green {
    width: 100%;
    //height: 30px;
    background-color: #50b578;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.status-bar-orange {
    width: 100%;
    //height: 30px;
    background-color: #FA9165;
    border-radius: 10px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.subheader-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .subheader-edit {
        width: 90% !important;
    }
}

.right-panel{
    display: flex;
    flex-direction: column;
}

.determinantes{
    flex: 1 !important;
}

.hist-clinica{
    margin-top: 50px !important
}

.checkbox {
    //margin-left: 1rem;
    accent-color: #dc042b;
}

.body-container {
    width: 90%;
    padding: 1.5rem 1rem;
}

.button-showdetails {
    width: 125px;
    min-width: max-content;
}

.text-topics {
    padding-left: 1rem;
    margin-bottom: 0rem !important;
}

.text-topics::after {
    left: 34px !important;
}

.check-topics {
    display: flex;
    margin: 0.25rem 0rem 0.5rem 0.5rem;
    align-items: center;
    label {
        color: #929191;
    }
}

.button-container {
    margin-top: 15px !important;
    padding: 0 15px;
}

.button-save {
    width: 157px;
    height: 34px;
    min-width: max-content;
    font-size: 14px;
    font-weight: 300;
}

.active-situation {
    background-color: #50b578;
    color: white;
    padding: 0.25rem;
    border-radius: 3px;
}

.historical-situation {
    background-color: #FA9165;
    color: white;
    padding: 0.25rem;
    border-radius: 3px;
}

.activity-img {
    max-width: 50vh;
    max-height: 28vh;
}

.title-image {
    margin-left: 1rem;
    width: 2rem;
    height: 1.5rem;
}

.show {
    display: block;
}

.hide {
    display: none;
}