.navbar {
  padding-left: 22px !important;
  border: none;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  top: 0;
  left: 0;
  position: fixed;
  justify-content: space-between;
  width: 100% !important;
  min-height: 66px;
  z-index: 1000;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .navbar-left-item {
    display: flex;
    float: left;

  }

  .navbar-right-item {
    width: 100%;
    margin-bottom: 0;
    //float: right !important;
    margin-top: 0;
    font-size: 18px;
    padding-left: 0 !important;
    display: flex;

    .items {
      float: left;
      padding: 20px 20px;
      padding-top: 22px;
      padding-bottom: 24px;
      color: #dc042b;
      line-height: 1.2;
      display: flex;
    }

    .items-logged {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
//      width: 8rem;
    }

    .navbar-access {
      //float: left;
      margin-left: auto;
      padding: 20px 20px;
      padding-top: 22px;
      padding-bottom: 24px;
      color: #dc042b;
      line-height: 1.2;
      display: flex;
      overflow-x: hidden;
    }

    .navbar-access-login{
      margin-left: auto;
      padding: 20px 20px;
      padding-top: 22px;
      padding-bottom: 24px;
      color: #dc042b;
      line-height: 1.2;
      display: flex;
    }

    .navbar-access-login:hover{
      background-color: #f3f7f9;
      cursor: pointer;
    }
  }
}

.activityDropdown {
  position: fixed;
  top: 120px;
  left: 9.8rem;
  z-index: 900;
  border: 1px rgb(233, 233, 233) solid;
}

.resourceDropdown {
  position: fixed;
  top: 120px;
  left: 19.2rem;
  z-index: 900;
  border: 1px rgb(233, 233, 233) solid;
}

.userDropdown {
  top: 120px;
  position: fixed;
  right: 0px;
  z-index: 900;
  border: 1px rgb(233, 233, 233) solid;
}


/*********ADMIN***********/

.activityDropdown-admin {
  position: fixed;
  top: 120px;
  left: 9.8rem;
  z-index: 900;
  border: 1px rgb(233, 233, 233) solid;
}


.resourceDropdown-admin {
  position: fixed;
  top: 120px;
  left: 19.2rem;
  z-index: 900;
  border: 1px rgb(233, 233, 233) solid;
}

/*********USER***********/

.activityDropdown-user {
  position: fixed;
  top: 120px;
  left: 9.8rem;
  z-index: 900;
  border: 1px rgb(233, 233, 233) solid;
}


.resourceDropdown-user {
  position: fixed;
  top: 120px;
  left: 19.2rem;
  z-index: 900;
  border: 1px rgb(233, 233, 233) solid;
}



@media(max-width: 984px){
  .navbar-labels{
    display: none;
  }

  .resourceDropdown {
    left: 8.8rem;
    z-index: 900;
    border: 1px rgb(233, 233, 233) solid;
  }
  
  .activityDropdown {
    left: 5rem;
    z-index: 900;
    border: 1px rgb(233, 233, 233) solid;
  }


  .resourceDropdown-admin {
    top: 120px;
    margin-top: 0;
    right: auto !important;
    left: 8.8rem;
  }
  
  .activityDropdown-admin {
    top: 120px;
    margin-top: 0;
    left: 5rem;
    right: auto !important;
  }


  .activityDropdown-user {
    left: 5rem;
    right: auto !important;
    top: 120px;
    margin-top: 0px;
  }
    
  .resourceDropdown-user {
    left: 8.8rem;
    right: auto !important;
    top: 120px;
    margin-top: 0px;
  }

  .navbar-right-item {
    margin-bottom: 0;
    width: 100%;
    margin-top: 0;
  }

}


.session-btn{
  display: none !important;
}


@media (max-width: 660px){
  .navbar-access {
    display: none !important;
  }

  .session-btn{
    display: block !important;
    margin-left: auto;
  }

}



@media (min-width: 600px){
	.MuiToolbar-regular {
		min-height: 90px;
		}
}

.items:hover {
  cursor: pointer;
  background-color: #f3f7f9;
}

.navbar-access {
  cursor: auto;
  justify-content: end;
  width: 300px;
}


.logo {
  width: 50px;
  vertical-align: middle;
}

.left-text {
  color: #dc042b;
  font-weight: 400;
  line-height: 1.2;
  font-size: 24px;
  padding: 20px 20px 0px 20px;
  cursor: pointer;
}

.left-logo {
  margin-top: 15px;
}

.icons {
  width: 18px;
  padding-right: 5px;
  filter: invert(14%) sepia(71%) saturate(6166%) hue-rotate(343deg)
    brightness(84%) contrast(106%);
}

.dropdown-item {
  z-index: 900;
  font-weight: 300 !important;
  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
  display: block;
  padding: 10px 20px;
  clear: both;
  line-height: 1.57142857;
  color: #76838f;
  white-space: nowrap;
  font-size: 17px !important;
  background-color: #ffffff;
}

.dropdown-item:hover {
  z-index: 900;
  color: #76838f;
  text-decoration: none;
  background-color: #f3f7f9;
  cursor: pointer;
}

.dropdown-icons {
  z-index: 900;
  width: 20px !important;
  margin-right: 5px !important;
  filter: invert(53%) sepia(7%) saturate(779%) hue-rotate(167deg)
    brightness(94%) contrast(82%);
}