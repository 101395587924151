.main-container {
    padding-bottom: 30px !important;
    padding-top: 55px !important;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1800px !important;
}

.header {
    height: 60px;
    margin-top: 50px;
    .header-text {
        display: flex;
        align-items: center;
    }

}

.body-style {
    width: 90%;
    max-width: 1200px;
    margin: 15px auto 40px auto;
}

.icons-container {
    padding-top: 20px !important;
    margin-bottom: 22px;
    display: flex;

    .icon-circle {
        position: relative;
        padding: 0;
        margin: 0;
        z-index: 1;
        display: flex;
        width: 36px;
        height: 36px;
        line-height: 32px;
        justify-content: center;
        background: #ccd5db;
        border: 2px solid #ccd5db;
        border-radius: 50%;
        font-size: 18px;
    }

    /* .icon-circle::before {
        content: '';
        width: 240px;
        height: 1px;
        position: absolute;
        top: 15px;
        left: 84px;
        background: #ccd5db;
    }
    
    .icon-circle:last-child::before {
        display: none;
    } */

}

.circle-active {
    transform: scale(1.2) !important;
    background: #fff !important;
    border-color: #DC042B !important;
    z-index: 1 !important;

    /* .circle-active::before {
        content: '';
        width: 240px;
        height: 1px;
        position: absolute;
        top: 15px;
        left: 84px;
        background: #f44336;
    } */

}

.circle-completed {
    background: #DC042B !important;
    border-color: #DC042B !important;
    z-index: 1 !important;

}

.icon-active {
    color: #DC042B;
    filter: invert(24%) sepia(92%) saturate(4972%) hue-rotate(339deg) brightness(81%) contrast(116%);

}

.icon-completed {
    color: #DC042B;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(268deg) brightness(107%) contrast(109%);
}

.icon-desactive {
    filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(200deg) brightness(101%) contrast(101%);
    width: 18px !important;
}

.circle-text {
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    color: #526069;
    text-overflow: ellipsis;
    word-wrap: normal;
    white-space: nowrap;
    font-size: 16px;
    margin-top: 1.3em;
}

@media(max-width: 630px){
    .circle-text{
        display: none;
    }

}

.form-control {
    //width: 570px !important;
    width: 90% !important;
    margin-bottom: 15px !important;
    padding: 0 5px 0 5px !important;
}

.input-file-container {
    //width: 570px !important;
    margin-bottom: 15px !important;@media(max-width: 630px){
        .circle-text{
            display: none;
        }
    
    }
    padding: 0 5px 0 5px !important;
    display: flex;
}

.requieredfield {
    margin-bottom: 15px!important;
    width: 100%;
}

.control-label {
    margin-top: 11px !important;
    margin-bottom: 11px !important;
}

.data-container {
    width: 50%;
    margin-bottom: 15px !important;
}

.first-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}

.first-container input[type=text] {
    width: 60% !important;
}

.first-container select {
    width: 60% !important;
}

.first-container textarea{
    width: 100% !important;
    height: 100px;
}


@media(max-width: 550px){
    .first-container input[type=text] {
        width: 100% !important;
    }
    
    .first-container select {
        width: 100% !important;
    }
}

.second-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.second-container div{
    flex-basis: 48%;
}

@media(max-width: 700px){
    .second-container div{
        flex-basis: 100%;
        gap: 10px;
    }
}

.select-inputs {
    height: 36px !important;
    //width: 570px !important;
    margin-bottom: 15px !important;
}

.temporal-field {
    right: 0;
}

.ubication-fields-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ubication-fields{
    width: 145px;
    margin-right: 5px;
}

.ubication-selects {
    width: 100%;
    margin-right: 11px !important;
}

.ubication-inputs {
    width: 100% !important;
    padding: 0 !important;
}

.map-container {
    height: 200px;
    width: 100px;
}

.buttons-container {
    display: flex;
    flex-direction: row;

    .place-button {
        font-weight: 300 !important;
        font-size: 14px !important;
        height: 34px;
        width: 125px;
        min-width: max-content;
        margin-right: 5px;
    }
}

.info-container {
    display: flex;
    justify-content: center;
}

.info{
    position: relative;
    font-size: 100%;
    margin-bottom: 15px !important;
    background-color: rgba(242,166,84,.1);
    padding: 15px 20px;
    border-left: 4px solid #f2a654;
    border-radius: 3px;
    width: 90%;
    max-width: 1200px;

}

.buttons-container-index {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    justify-content: right;

    .button-index {
        font-size: 14px !important;
        font-weight: 300 !important;
        width: 108px !important;
        height: 34px !important;
        margin-left: 5px;
        justify-content: center;
    }

    .button-return {
        background-color: #c8c8c8;
        width: 108px !important;
        height: 34px !important;
        margin-right: 5px;
        justify-content: center;
        min-width: max-content;
        color: #526069;
        font-weight: 300;
    }

    .button-restart{
        background-color: #EFEFEF;
        color: #76838F
    }

}

.footer-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.upload-button {
    display: flex !important;
    align-items: center;
    width: 30px !important;
    
    .upload-icon {
        width: 18px;
        height: 22px;
    }

    .file-input {
        width: 30px;
        height: 40px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        border-radius: 0px !important;
        margin-left: -5px !important;
    }

}

.first-preview{
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.rigth-input {
    justify-content: flex-end !important;
}

.check-activity {
    left: -30px;
    accent-color: #dc042b;
}

.text-topics::after {
    left: -20px !important;
}

.icon-check {
    padding-top: 6px;
    height: 25px;
} 

.check-topics {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.confirmation-element {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid transparent;
}

.checkbox-container{
    padding: 15px 30px 15px 30px;
}